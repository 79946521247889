import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "toggle"
    }}>{`Toggle`}</h1>
    <p><inlineCode parentName="p">{`import { Toggle } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`Everyone loves a toggle. Here's one for you`}</p>
    <h2 {...{
      "id": "code-example"
    }}>{`Code Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, { Component } from 'react'
import { Toggle } from '@foodsby/nutrient'
import { ThemeProvider } from 'styled-components'
import { themeConWeb, themeEnterprise, themeGO } from '../themes'

export default class Demo extends Component {
  state = {
    checkedLeft: false,
    checkedRight: false
  }

  handleClickLeft = () => {
    const { checkedLeft } = this.state
    this.setState({ checkedLeft: !checkedLeft })
  }
  handleClickRight = () => {
    const { checkedRight } = this.state
    this.setState({ checkedRight: !checkedRight })
  }
  handleClick = () => {
    const { checked } = this.state
    this.setState({ checked: !checked })
  }
  render() {
    const { checked, checkedLeft, checkedRight } = this.state
    return (
      <ThemeProvider theme={themeConWeb}>
        <Toggle
          id="test"
          name="test"
          label="Plain Toggle"
          onChange={this.handleClick}
          checked={checked}
        />
        <Toggle
          id="test2"
          name="test2"
          label="Left Toggle"
          direction="left"
          uncheckedLabel="no"
          checkedLabel="yes"
          onChange={this.handleClickLeft}
          checked={checkedLeft}
        />
        <Toggle
          id="test3"
          name="test3"
          direction="right"
          label="Right Toggle"
          uncheckedLabel="off"
          checkedLabel="on"
          onChange={this.handleClickRight}
          checked={checkedRight}
        />
      </ThemeProvider>    
    )
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      